import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { RoutesList } from "./methods/UseImports";
import { motion } from "framer-motion";

const App = () => {
    return (
        <React.Fragment>
            <Router basename="/">
                <Routes>
                    {RoutesList.map((ele: any) => {
                        return ele.path.map((ele2: any) => {
                            return (
                                <Route
                                    key={ele2}
                                    path={ele2}
                                    element={
                                        <Suspense
                                            fallback={
                                                <motion.div
                                                    animate={{
                                                        scale: [1, 1, 1, 1, 1],
                                                        rotate: [0, 30, 60, 240, 360],
                                                    }}
                                                >
                                                    <div className="loader"></div>
                                                </motion.div>
                                            }
                                        >
                                            {ele.element && <ele.element />}
                                        </Suspense>
                                    }
                                />
                            );
                        });
                    })}
                </Routes>
            </Router>
        </React.Fragment>
    );
};

export default App;